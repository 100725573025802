import Button from "../../components/Button";
import { getCart, saveCart } from "../../utils/localstorage";
import { useContext, useEffect, useMemo, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isProb3 } from "../../utils/siteOrigin";
import { CartContext } from "../../contexts/CartContext";
import CartProduct from "./CartProduct";
import ArrowRightIcon from "@heroicons/react/24/outline/ArrowRightIcon";

export default function CheckOut() {
  const [cartProducts, setCartProducts] = useState(getCart() || []);
  const navigate = useNavigate();
  const prevPage = isProb3() ? "/bands" : "/become-a-member";
  const cartContext = useContext(CartContext);

  const total = useMemo(() => {
    return cartProducts.reduce((total, p) => {
      return total + p.product.Price * p.qty;
    }, 0);
  }, [cartProducts]);
  const removeItem = (sku) => {
    setCartProducts((prev) => prev.filter((p) => p.product.Sku !== sku));
  };

  useEffect(() => {
    saveCart(cartProducts);
  }, [cartProducts]);

  const navigateToShipping = () => {
    if (!total) {
      toast.error("You must put a product in your cart!");
      return;
    } else {
      navigate("/shipping");
    }
  };

  const emptyCart = () => {
    setCartProducts([]);
    cartContext.save([]);
  };

  return (
    <section className="container mx-auto flex-1 p-5 flex flex-col lg:max-w-full relative">
      <div className="grid grid-cols-4 lg:gap-10 sm:gap-5 gap-2">
        <div className="lg:col-span-3 col-span-5">
          <h1 className="md:text-5xl pt-2 text-3xl sm:text-4xl">
            <span>B</span>
            <span className="text-[#00adef] mr-2">3</span>
            <span>SHOPPING CART</span>
          </h1>

          <div className="flex flex-col gap-3">
            {cartProducts?.length > 0 ? (
              <>
                <div className="flex flex-row justify-between items-end mt-4 md:mt-7">
                  <h1 className="text-xl md:text-2xl font-light">Items: </h1>
                  {cartProducts.length > 0 && (
                    <button
                      class=" justify-center bg-[#00adef] hover:bg-[#008bb9] px-3 py-2 text-white shadow-sm ml-2 sm:ml-3 sm:w-auto lg:text-xl text-sm rounded-full font-light"
                      onClick={emptyCart}
                    >
                      Remove Items
                    </button>
                  )}
                </div>
                <div className="grid gap-4">
                  {cartProducts.map((p, index) => {
                    // TODO : Need to update when array work done
                    const isZeroPriceOrSpecialProduct =
                      p.product.Price === 0 ||
                      p.product.ProductName === "B3 Bands Pair Size 5";

                    const isFirstZeroPriceProduct =
                      isZeroPriceOrSpecialProduct &&
                      (index === 0 ||
                        cartProducts[index - 1].product.Price > 30);

                    const isLastInGroup =
                      isZeroPriceOrSpecialProduct &&
                      (index === cartProducts.length - 1 ||
                        (cartProducts[index + 1].product.Price > 0 &&
                          cartProducts[index + 1].product.ProductName !==
                            "B3 Bands Pair Size 5"));
                    const isMainProduct =
                      p.product.Price > 0 &&
                      p.product.ProductName !== "B3 Bands Pair Size 5";
                    return (
                      <>
                        {isFirstZeroPriceProduct && (
                          <h1 className="col-span-2 text-xl md:text-2xl font-light">
                            Included in your bundle:{" "}
                          </h1>
                        )}
                        <div
                          key={p.product.Sku}
                          className={`${
                            isMainProduct ? "col-span-2" : "col-span-1"
                          } border  rounded-lg shadow-sm border-gray-300`}
                        >
                          <CartProduct
                            isMainProduct={isMainProduct}
                            product={p.product}
                            qty={p.qty}
                          />
                        </div>
                        {isLastInGroup && (
                          <div className="col-span-2 border-b border-gray-500 my-1"></div>
                        )}
                      </>
                    );
                  })}
                </div>

                <div className="w-full  flex justify-end text-xl md:text-2xl ">
                  Subtotal: ${total.toFixed(2)}
                </div>
                <div className="font-bold text-center text-md border-0 border-b border-gray-300 md:border-b-2 pb-3 md:pb-4">
                  *If you need to make changes remove items with the button on top*
                </div>
                {cartProducts?.length > 0 && (
                  <div className="flex justify-end">
                    <Button
                      class="w-full justify-center bg-[#00adef] hover:bg-[#008bb9] px-5 py-2 text-white shadow-sm sm:ml-3 sm:w-auto lg:text-xl text-sm rounded-full font-light"
                      onClick={navigateToShipping}
                    >
                      Checkout
                      <div className="inline-block pl-1 relative top-[3px]">
                        <ArrowRightIcon
                          fill="white"
                          className="h-[0.9rem] md:h-[1.1rem] w-[1.1rem]"
                          aria-hidden="true"
                          color="white"
                        />
                      </div>
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="items-end mt-4 md:mt-7">
                  <h1 className="text-xl md:text-2xl font-light">
                    Looks like you haven’t added anything yet, let’s get you
                    started!
                  </h1>
                  <NavLink key="b3 bundle" to="/">
                    <div className="mt-4 md:mt-7">
                      <Button
                        class="!ml-0 uppercase w-full justify-center bg-[#00adef] hover:bg-[#008bb9] px-5 py-2 text-white shadow-sm sm:ml-3 sm:w-auto lg:text-xl text-sm rounded-full font-light"
                        // onClick={emptyCart}
                      >
                        Continue Shopping
                        <div className="inline-block pl-1 relative top-[3px]"></div>
                      </Button>
                    </div>
                  </NavLink>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
