import React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useState } from 'react'
function ExpandableImage({alt,src,className}) {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    return <><Transition appear show={isPopupOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setIsPopupOpen(false)}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
  
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <img alt={alt} src={src} className="w-full h-full mt-2" />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
      <img alt={alt} src={src} className={className} onClick={() => setIsPopupOpen(true)} />
    </>
}

export default ExpandableImage