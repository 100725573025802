import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import Bands from "./pages/bands";
import CheckOut from "./pages/checkout";
import Confirmation from "./pages/confirmation";
import OrderSuccess from "./pages/confirmation/OrderSuccess";
import CertificationCourse from "./pages/course";
import Gear from "./pages/gear";
import Member from "./pages/member";
import NutritionProducts from "./pages/nutrition-products";
import Payment from "./pages/payment";
import Shipping from "./pages/shipping";
import Bundle from "./pages/bundle";
import Home from "./pages/home";
import PaypalSubscription from "./pages/paypal-subscription";

export const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "bundle",
        element: <Bundle />,
      },
      {
        path: "bands",
        element: <Bands />,
      },
      {
        path: "gear",
        element: <Gear />,
      },
      {
        path: "nutrition-products",
        element: <NutritionProducts />,
      },
      {
        path: "become-a-member",
        element: <Member />,
      },
      {
        path: "certification-course",
        element: <CertificationCourse />,
      },
      {
        path: "check-out",
        element: <CheckOut />,
      },
      {
        path: "payment",
        element: <Payment />,
      },
      {
        path: "shipping",
        element: <Shipping />,
      },
      {
        path: "confirmation",
        element: <Confirmation />,
      },
      {
        path: "order-success",
        element: <OrderSuccess />,
      },
    ],
  },
  {
    path: "paypal-subscription",
    element: <PaypalSubscription />,
  },
]);
