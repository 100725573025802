import { NavLink } from "react-router-dom";
import ListItem from "../../components/ListItem";

export default function B3AlertDetails() {
  return (
    <div>
      <ul>
        <div className="relative xl:py-1.5 py-1">
          <p className="lg:text-base font-bold text-[#FF0000] leading-5 text-base text-[#00adef] flex uppercase">
            ALERT IS OUT OF STOCK - NEW FORMULA COMING SOON !
          </p>
        </div>
        <ListItem>B3 Alert is undergoing a BIG improvement!</ListItem>
        <ListItem>More Natural Ingredients</ListItem>
        <ListItem>New HGH promoting ingredients</ListItem>
        <ListItem>New Pre-Workout ingredients</ListItem>
        <ListItem>No more sucralose</ListItem>
        <div className="relative xl:py-1.5 py-1">
          <p className="lg:text-base font-light leading-5 text-base text-[#00adef] flex uppercase">
            READY IN FEBRUARY
          </p>
        </div>
        {/* <ListItem>
          <NavLink key='for more info' target="_blank" to="https://b3sciences.com/alert/">
            <span className="uppercase">For more information on <span className="text-black">B</span>3 Alert go here(Link)</span>
          </NavLink>
        </ListItem> */}
        {/* <ListItem>Delicious Black Cherry flavor</ListItem>
        <ListItem>Unique energy formula specifically designed with 15 ingredients that have been shown to enhance focus, brain power and energy levels.</ListItem>
        <ListItem>Vegan, Preservative Free, Gluten Free and Non-GMO</ListItem>
        <ListItem>30 SERVINGS</ListItem>
        <ListItem>MIX WITH WATER OR MAKE A FRUIT SMOOTHIE</ListItem>
        <ListItem>
          <NavLink key='for more info' target="_blank" to="https://b3sciences.com/alert/">
            <span className="uppercase">For more information on <span className="text-black">B</span>3 Alert go here(Link)</span>
          </NavLink>
        </ListItem> */}
      </ul>
    </div>
  );
}
