import clsx from "clsx";

export default function Button({ className, children, ...rest }) {
  return (
    <button
      className={clsx(
        "justify-center bg-[#00adef] hover:bg-[#008bb9] px-3 py-2 text-white shadow-sm sm:ml-3 sm:w-auto",
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
}
