import { NavLink } from "react-router-dom"
import ListItem from "../../components/ListItem"

export default function B3StartDetails() {
  return (
    <div>
      <ul>
        <ListItem>DELICIOUS TROPICAL FLAVOR</ListItem>
        <ListItem>TARGETED COLLAGEN PEPTIDES</ListItem>
        <ListItem>VITAMINS, MINERALS, ALOE, NATURAL ENERGY, SUPERFOOD & BIOTIN</ListItem>
        <ListItem>ALL-NATURAL, ARTIFICIAL SWEETENER FREE, PRESERVATIVE FREE, GLUTEN FREE AND NON-GMO</ListItem>
        <ListItem>30 SERVINGS</ListItem>
        <ListItem>MIX WITH WATER OR MAKE A FRUIT SMOOTHIE</ListItem>
        <ListItem>
          <NavLink key='for more info' to="https://b3sciences.com/start/" target="_blank">
            For more information on <span className="text-black">B</span>3 Start go here (link)
          </NavLink>
        </ListItem>
      </ul>
    </div>
  )
}
