import { NavLink } from "react-router-dom"
import ListItem from "../../components/ListItem"

export default function B3SSatisfyDetails() {
  return (
    <div>
      <ul>
        <ListItem>DELICIOUS STRAWBERRY SPLASH FLAVOR</ListItem>
        <ListItem>UNIQUE FORMULA SPECIFICALLY DESIGNED WITH 10 INGREDIENTS THAT CAN HELP WITH HUNGER CONTROL, METABOLISM AND WEIGHT MANAGEMENT</ListItem>
        <ListItem>VEGAN, PRESERVATIVE FREE, ARTIFICIAL SWEETENER FREE, GLUTEN FREE</ListItem>
        <ListItem>30 SERVINGS</ListItem>
        <ListItem>MIXES EASILY WITH WATER OR ADD TO A FRUIT SMOOTHIE</ListItem>
        <ListItem>FOR MORE INFORMATION on B3 SATISFY GO HERE (<a href=" https://b3sciences.com/satisfy/" target="_blank">LINK</a>)</ListItem>
      </ul>
    </div>
  )
}
