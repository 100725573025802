import { NavLink } from "react-router-dom"
import ListItem from "../../components/ListItem"

export default function B3MealDetails() {
  return (
    <div>
      <ul>
        <ListItem>DELICIOUS SALTED CARAMEL MOCHA FLAVOR</ListItem>
        <ListItem>PLANT PROTEIN (PEA, RICE)</ListItem>
        <ListItem>FRUITS, VEGETABLES, OMEGAS, ALOE, SUPERFOODS, MINERALS</ListItem>
        <ListItem>NO PRESERVATIVES, VEGAN AND GLUTEN FREE</ListItem>
        <ListItem>30 SERVINGS</ListItem>
        <ListItem>MIX WITH WATER OR MAKE A FROZEN SHAKE</ListItem>
        <ListItem>
          <NavLink key='for more info' target="_blank" to="https://b3sciences.com/meal/">
            <span className="uppercase">For more information on <span className="text-black">B</span>3 Meal go here(Link)</span>
          </NavLink>
        </ListItem>
      </ul>
    </div>
  )
}
