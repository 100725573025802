import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import { scrollIntoView } from "seamless-scroll-polyfill";
import AddToCartForm from "../../components/AddToCartForm";
import Button from "../../components/Button";
import Carousel from "../../components/Carousel";
import Bands from "./Bands";
import ArrowRightIcon from "@heroicons/react/24/outline/ArrowRightIcon";
import ArrowLeftIcon from "@heroicons/react/24/outline/ArrowLeftIcon";

import { isProb3 } from "../../utils/siteOrigin";
const SwiperImages = ["plank.png", "person4.jpg", "person1.jpg", "person5.jpg"];

export default function Page() {
  const nextPage = isProb3() ? "/check-out" : "/nutrition-products";
  const nextButtonRef = useRef(null);
  const scrollToElement = () => {
    if (nextButtonRef.current) {
      scrollIntoView(nextButtonRef.current, {
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };
  return (
    <section className="container mx-auto flex-1 p-5 flex flex-col lg:max-w-full relative">
      <div></div>
      <h1 className="md:text-6xl pt-2 text-4xl sm:text-5xl">
        <span>B</span>
        <span className="text-[#00adef] mr-3">3</span>
        <span>BANDS</span>
      </h1>
      <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1">
        <div className="flex flex-col mt-5">
          <Bands />
          <div className="">
            <AddToCartForm onClose={scrollToElement} />
          </div>
        </div>
        <div className="flex items-center">
          <Carousel swiperImages={SwiperImages} />
        </div>
      </div>
      {/* <div className="flex text-center md:justify-center relative items-center flex-col sm:flex-row justify-between">
        <div className="w-full flex items-center justify-center md:justify-start relative mr-2 pt-4">
          <div className="flex flex-col ">
            <img
              alt="paypal credit"
              src="./assets/images/paypal-credit.png"
              className="w-[100px] md:w-[120px]"
            />
            <span className="text-[10px] font-semibold">
              $0 DOWN 0% INTEREST FOR 6 MONTHS
            </span>
          </div>
          <img
            alt="money-guarantee"
            src="./assets/images/guarantee.jpg"
            className="w-[70px] md:w-[90px] top-6 right-5 md:top-2 ml-2 md:right-2"
          />
        </div>
      </div> */}
      <div className="w-full flex justify-between mt-10 mb-7 flex-row items-center">
        <NavLink key="b3 bands" to="/bundle">
          <Button className="uppercase text-xs md:text-base px-4 py-1 rounded-full font-light w-auto">
            <div className="inline-block pr-1 relative top-[3px]">
              <ArrowLeftIcon
                fill="white"
                className="h-[0.9rem] md:h-[1.1rem] w-[1.1rem]"
                aria-hidden="true"
                color="white"
              />
            </div>
            {"Back"}
          </Button>
        </NavLink>
        <NavLink
          key="nutrition products"
          to="/nutrition-products"
          ref={nextButtonRef}
        >
          <Button className="uppercase text-xs md:text-base px-4 py-1 rounded-full font-light w-auto">
            Next
            <div className="inline-block pl-1 relative top-[3px]">
              <ArrowRightIcon
                fill="white"
                className="h-[0.9rem] md:h-[1.1rem] w-[1.1rem]"
                aria-hidden="true"
                color="white"
              />
            </div>
          </Button>
        </NavLink>
      </div>
    </section>
  );
}
