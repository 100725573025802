import { Fragment, useContext, useState } from "react";
import { Listbox, Transition, Dialog } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
import { getCart, saveCart } from "../../utils/localstorage";
import Button from "../../components/Button";
import PRODUCTS from "../../../public/assets/products.json";
import { CartContext } from "../../contexts/CartContext";
import { useNavigate } from "react-router-dom";
import SizeChart from "./SizeChart";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";

const singleBundle = {
  Sku: "RCBurn1",
  ProductName: "10 Minute Burn – Single Pack",
  Description: "",
  Image: "bundle-cart-1.png",
  ImageSmall: "bundle-cart-1.png",
  Price: 524.99,
  Weight: 175,
};
const doubleBundle = {
  Sku: "RCBurn2",
  ProductName: "10 Minute Burn – Double Pack",
  Description: "",
  Image: "bundle-cart-2.png",
  ImageSmall: "bundle-cart-2.png",
  Price: 899.99,
  Weight: 400,
  qty: 2,
};

const armBands = [
  {
    Sku: "Bands1",
    ProductName: "B3 Band Size 1",
    Description: "B3 Band Size 1",
    Image: "BandSets.png",
    ImageSmall: "BandSets-sm.png",
    Price: 0.0,
    Weight: 0.0,
  },
  {
    Sku: "Bands2",
    ProductName: "B3 Band Size 2",
    Description: "B3 Band Size 2",
    Image: "BandSets.png",
    ImageSmall: "BandSets-sm.png",
    Price: 0.0,
    Weight: 0.0,
  },
  {
    Sku: "Bands3",
    ProductName: "B3 Band Size 3",
    Description: "B3 Band Size 3",
    Image: "BandSets.png",
    ImageSmall: "BandSets-sm.png",
    Price: 0.0,
    Weight: 0.0,
  },
];

const legBands = [
  {
    Sku: "Bands3",
    ProductName: "B3 Band Size 3",
    Description: "B3 Band Size 3",
    Image: "BandSets.png",
    ImageSmall: "BandSets-sm.png",
    Price: 0.0,
    Weight: 0.0,
  },
  {
    Sku: "Bands4",
    ProductName: "B3 Band Size 4",
    Description: "B3 Band Size 4",
    Image: "BandSets.png",
    ImageSmall: "BandSets-sm.png",
    Price: 0.0,
    Weight: 0.0,
  },
  {
    Sku: "Bands5",
    ProductName: "B3 Band Size 5",
    Description: "B3 Band Size 5",
    Image: "BandSets.png",
    ImageSmall: "BandSets-sm.png",
    Price: 0.0,
    Weight: 0.0,
  },
];

const additionalSize5 = {
  Sku: "RCSize5",
  ProductName: "B3 Bands Pair Size 5",
  Description: "",
  Image: "BandSets.png",
  ImageSmall: "",
  Price: 30,
  Weight: 40,
};

const startFTD = {
  Sku: "StartFTD",
  ProductName: "B3 Start Caffeine Free - 30 Servings",
  Description: "",
  Image: "Start.png",
  ImageSmall: "",
  Price: 0,
  Weight: 16,
};

const startTD = {
  Sku: "StartTD",
  ProductName: "B3 Start - 30 Servings",
  Description: "",
  Image: "StartFree.png",
  ImageSmall: "",
  Price: 0,
  Weight: 16,
};

const additionalItems = [
  {
    Description: "",
    Image: "pump-cart.png",
    ImageSmall: "pump-cart.png",
    Price: 0.0,
    ProductName: "B3 Bands Pump",
    Sku: "",
    Weight: 0.0,
  },
  {
    Description: "",
    Image: "exercise-tubing-cart.png",
    ImageSmall: "exercise-tubing-cart.png",
    Price: 0.0,
    ProductName: "B3 Exercise Tubing",
    Sku: "",
    Weight: 0.0,
  },
  {
    Description: "",
    Image: "duffel-bag-cart.png",
    ImageSmall: "duffel-bag-cart.png",
    Price: 0.0,
    ProductName: "B3 Duffel Bag",
    Sku: "",
    Weight: 0.0,
  },
];

const memberProduct = {
  Price: 0,
  Sku: "MemKitTD",
  ProductName: "B3 Sciences - Yearly Membership",
  Image: "memkit.png",
};

export default function BundleAddToCartForm({
  isSingle,
  sku,
  handleAddToCart,
  caffeineFree,
}) {
  const cartContext = useContext(CartContext);
  let navigate = useNavigate();
  const notify = () => toast("Product is added!");
  const [selected, setSelected] = useState(
    isSingle ? singleBundle : doubleBundle
  );
  const [armSizes, setArmSizes] = useState(
    isSingle ? [armBands[0]] : Array(2).fill(armBands[0])
  );
  const [legSizes, setLegSizes] = useState(
    isSingle ? [legBands[0]] : Array(2).fill(legBands[0])
  );

  const [isOpen, setIsOpen] = useState(false);
  const product = PRODUCTS.find((p) => p.Sku === sku);

  const closeModal = () => {
    setIsOpen(false);
  };

  const addProduct = () => {
    const cart = getCart();
    notify();
    // Add ProdctType - SET
    const existing = cart.find((p) => p.product.Sku === selected.Sku);
    if (existing) {
      navigate("/check-out");
      return;
    } else {
      cart.push({ product: selected, qty: 1 });
      cart.push({
        product: caffeineFree ? startFTD : startTD,
        qty: isSingle ? 1 : 2,
      });
    }

    // Add individual items
    armSizes.concat(legSizes).forEach((product) => {
      const existing = cart.find((p) => p.product.Sku === product.Sku);
      if (existing) {
        existing.qty = (Number(existing.qty) || 0) + 2;
      } else {
        cart.push({ product, qty: 2 });
      }
      if (product.Sku === "Bands5") {
        const existingAdditional = cart.find(
          (p) => p.product.Sku === "RCSize5"
        );
        if (existingAdditional) {
          existingAdditional.qty = (Number(existingAdditional.qty) || 0) + 1;
        } else {
          cart.push({ product: additionalSize5, qty: 1 });
        }
      }
    });

    additionalItems.forEach((product) => {
      cart.push({ product, qty: isSingle ? 1 : 2 });
    });
    const existingMemberKit = cart.find(
      (p) => p?.product?.Sku === memberProduct?.Sku
    );
    if (!existingMemberKit) {
      cart.push({ product: memberProduct, qty: 1 });
    }
    cartContext.save(cart);
    setIsOpen(false);
    handleAddToCart();
  };

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <div className="flex flex-col mt-2">
      <div className="md:flex flex-row items-center gap-2 mt-3">
        <div className="flex items-center font-medium mr-4">
          <span className="text-xl sm:mr-0 mr-4">${product.Price}</span>
          <Button
            type="button"
            onClick={openModal}
            className="text-base md:text-lg px-5 py-2 rounded-full font-light"
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
          >
            ADD TO CART
          </Button>
        </div>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="lg:max-w-5xl max-w-3xl transform rounded-2xl bg-white p-10 text-left align-middle shadow-xl transition-all sm:max-h-auto max-h-[700px] overflow-auto">
                  <Dialog.Title
                    as="h3"
                    className="text-xl md:text-2xl font-medium leading-6 text-[#00adef]"
                  >
                    Size Selection
                  </Dialog.Title>
                  <div>
                    <div className="flex lg:flex-row flex-col items-center justify-center">
                      <div className="flex flex-col overflow-x-visible mb-4 md:p-4 md:min-h-[400px] justify-center">
                        {Array.from(Array(selected.qty), (e, i) => {
                          return (
                            <div key={i} className="mt-4">
                              <div className="text-2xl capitalize text-[#00adef] mb-3">
                                Set #{i + 1}
                              </div>
                              <div className="flex gap-2 flex-col sm:flex-row">
                                <div className="flex flex-col">
                                  <div className="text-base md:text-xl capitalize mb-1 md:mb-2">
                                    Arm Sizes
                                  </div>
                                  <Listbox
                                    value={armSizes[i]}
                                    onChange={(value) =>
                                      setArmSizes((prev) => {
                                        const newValue = [...prev];
                                        newValue[i] = value;
                                        return newValue;
                                      })
                                    }
                                  >
                                    <div className="relative mt-1">
                                      <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white text-sm md:text-base px-3 py-2 md:px-6 md:py-4 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-2xl min-w-[250px] border">
                                        <span className="block truncate">
                                          {armSizes[i].ProductName}
                                        </span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                          <ChevronUpDownIcon
                                            className="h-5 w-5 text-gray-400 sm:text-2xl"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      </Listbox.Button>
                                      <Transition
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <Listbox.Options className="md:absolute relative mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                                          {armBands.map((band, idx) => (
                                            <Listbox.Option
                                              key={band.Sku}
                                              className={({ active }) =>
                                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                  active
                                                    ? "bg-amber-100 text-amber-900"
                                                    : "text-gray-900"
                                                }`
                                              }
                                              value={band}
                                            >
                                              <div className="flex flex-col text-base font-light">
                                                <div>
                                                  <span
                                                    className={`block truncate ${
                                                      armSizes[i].Sku ===
                                                      band.Sku
                                                        ? "font-normal"
                                                        : "font-light"
                                                    }`}
                                                  >
                                                    {band.ProductName}
                                                  </span>
                                                  {armSizes[i].Sku ===
                                                  band.Sku ? (
                                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                      <CheckIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                      />
                                                    </span>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </Listbox.Option>
                                          ))}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                  </Listbox>
                                </div>
                                <div className="flex flex-col sm:mt-0 mt-3">
                                  <div className="text-base md:text-xl capitalize mb-1 md:mb-2">
                                    Leg Sizes
                                  </div>
                                  <Listbox
                                    value={legSizes[i]}
                                    onChange={(value) =>
                                      setLegSizes((prev) => {
                                        const newValue = [...prev];
                                        newValue[i] = value;
                                        return newValue;
                                      })
                                    }
                                  >
                                    <div className="relative mt-1">
                                      <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white text-sm md:text-base px-3 py-2 md:px-6 md:py-4 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-2xl min-w-[250px] border">
                                        <span className="block truncate">
                                          {legSizes[i].ProductName}
                                        </span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                          <ChevronUpDownIcon
                                            className="h-5 w-5 text-gray-400 sm:text-2xl"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      </Listbox.Button>
                                      <Transition
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <Listbox.Options className="md:absolute relative mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                                          {legBands.map((band) => (
                                            <Listbox.Option
                                              key={band.Sku}
                                              className={({ active }) =>
                                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                  active
                                                    ? "bg-amber-100 text-amber-900"
                                                    : "text-gray-900"
                                                }`
                                              }
                                              value={band}
                                            >
                                              <div className="flex flex-col text-base font-light">
                                                <div>
                                                  <span
                                                    className={`block truncate ${
                                                      legSizes[i].Sku ===
                                                      band.Sku
                                                        ? "font-normal"
                                                        : "font-light"
                                                    }`}
                                                  >
                                                    {band.ProductName}
                                                  </span>
                                                  {legSizes[i].Sku ===
                                                  band.Sku ? (
                                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                      <CheckIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                      />
                                                    </span>
                                                  ) : null}
                                                </div>
                                                {band.Sku === "Bands5" && (
                                                  <div className="text-[#00adef] text-sm">
                                                    Additional $30
                                                  </div>
                                                )}
                                              </div>
                                            </Listbox.Option>
                                          ))}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                  </Listbox>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="hidden sm:block min-w-[100px] flex justify-center ">
                        <SizeChart />
                      </div>

                      <div className="sm:hidden mx-auto w-full rounded-2xl shadow bg-white max-w-[250px]">
                        <Disclosure as="div" className="mt-2">
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="flex w-full justify-between cursor-default rounded-lg bg-white text-sm md:text-base px-3 py-2 md:px-6 md:py-4 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-2xl min-w-[250px] border">
                                <span className="text-md font-500 capitalize mb-1 md:mb-2">
                                  Size Chart
                                </span>
                                <ChevronUpIcon
                                  className={`${
                                    open ? "rotate-180 transform" : ""
                                  } h-5 w-5`}
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                <SizeChart />
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      </div>
                    </div>
                    <div className="flex justify-center mt-3 w-full">
                      <Button
                        type="button"
                        onClick={addProduct}
                        className="text-base md:text-base md:text-lg px-5 py-2 rounded-full font-light"
                        data-mdb-ripple="true"
                        data-mdb-ripple-color="light"
                      >
                        ADD TO CART
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
