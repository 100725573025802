export default function SizeChart() {
  return (
    <div className="flex flex-col sm:flex-row ">
      <div className="flex justify-center items-center">
        <img
          src="assets/images/body-with-product.jpg"
          alt="body-with-product"
          className="w-[170px] h-[300px] mr-4"
        />
      </div>
      <div className="flex flex-col justify-between max-w-[340px]">
        <div>
          <p>Size for Arms</p>
          <div>
            <span className="text-[#00adef] mr-2">Size #1</span>
            <span>7in to 11.75in (18cm to 30cm)</span>
          </div>
          <div>
            <span className="text-[#00adef] mr-2">Size #2</span>
            <span>12in to 17.25in (30.5cm+ to 44cm)</span>
          </div>
          <div>
            <span className="text-[#00adef] mr-2">Size #3</span>
            <span>17.5in to 23.25in (44.5cm to 59cm)</span>
          </div>
        </div>

        <div>
          <p>Size for Legs</p>
          <div>
            <span className="text-[#00adef] mr-2">Size #3</span>
            <span>17.5in to 23.25in (44.5cm to 59cm)</span>
          </div>
          <div>
            <span className="text-[#00adef] mr-2">Size #4</span>
            <span>23.5in to 31.75in (60cm+ to 81cm)</span>
          </div>
          <div>
            <span className="text-[#00adef] mr-2">Size #5</span>
            <span>32in to 40in (81.5cm to 101.5cm)</span>
          </div>
        </div>

        <div>
          <p>Common Sizes</p>
          <div>
            <span className="text-[#00adef] mr-2">#2 + #3</span>
          </div>
          <span className="text-gray-700 text-xs">
            *Measure near the arm pit above the bicep and below the deltoid
            muscles, and the widest the part of the thigh
          </span>
        </div>
      </div>
    </div>
  );
}
