import Button from "../../components/Button";
import PRODUCTS from "../../../public/assets/products.json";

import "./styles.scss";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { getCart, saveCart } from "../../utils/localstorage";

export default function Gear() {
  const gearProductSKUs = ["ExerTub", "BackPack", "ShakerCO"];
  const gearProducts = PRODUCTS.filter((product) =>
    gearProductSKUs.includes(product.Sku)
  );
  const notify = () => toast("Product is added!");

  const handleAddToCart = (e) => {
    const sku = e.target.dataset["sku"];
    const qty = Number(document.getElementById(`qty-${sku}`).value) || 0;
    const product = PRODUCTS.find((p) => p.Sku === sku);

    if (qty > 0) {
      notify();
      const cart = getCart();
      const existing = cart.find((p) => p.product.Sku === sku);
      if (existing) {
        existing.qty = (Number(existing.qty) || 0) + qty;
      } else {
        cart.push({ product, qty });
      }
      saveCart(cart);
    }
  };

  return (
    <section className="container mx-auto flex-1 p-5 flex flex-col lg:max-w-full relative">
      {/* <div>
        <img alt="money-guarantee" src="./assets/images/guarantee.jpg" className="absolute md:w-[100px] w-[60px] top-6 right-5 md:top-2 md:right-2" />
      </div> */}
      <h1 className="md:text-5xl pt-2 text-3xl sm:text-4xl">
        <span>B</span>
        <span className="text-[#00adef] mr-2">3</span>
        <span>GEAR</span>
      </h1>
      <div className="grid lg:grid-cols-3 gap-x-8 gap-y-10 xl:px-20 pt-10 lg:px-5 sm:grid-cols-2">
        {gearProducts.map((product) => (
          <div
            className="flex flex-col shadow-lg border p-4 rounded-lg"
            key={product.ProductName}
          >
            <span className="border-b border-gray-900 text-2xl font-semibold mb-2">
              {product.ProductName}
            </span>
            <div className="flex flex-col items-center">
              <img
                alt={product.ProductName}
                src={`./assets/images/products/${product.Image}`}
                className="w-full border"
              />
              <div className="flex justify-between w-full items-center my-2">
                <div className="text-2xl">${product.Price}</div>
                <div className="flex items-center">
                  <span className="text-2xl mr-2">QTY : </span>
                  <input
                    type="number"
                    name="qty"
                    id={`qty-${product.Sku}`}
                    defaultValue={1}
                    className="block flex-1 rounded border md:border-gray-400 border-[#00adef] px-2 py-1.5 w-[65px] text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6 "
                    placeholder="Qty"
                  />
                </div>
              </div>
            </div>
            <div className="description">
              <div dangerouslySetInnerHTML={{ __html: product.Description }} />
            </div>
            <div className="flex justify-center pt-3 mt-auto">
              <Button
                className="text-base md:text-lg px-5 py-2 rounded-full font-light"
                data-sku={product.Sku}
                onClick={handleAddToCart}
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
              >
                ADD TO CART
              </Button>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full flex justify-between mt-10 sm:flex-row flex-col">
        <NavLink key="b3 bands" to="/bands">
          <Button className="text-base md:text-lg px-5 py-2 rounded-full font-light sm:w-auto w-full">
            {"< B3 BANDS"}
          </Button>
        </NavLink>
        <NavLink key="nutrition products" to="/nutrition-products">
          <Button className="text-base md:text-lg px-5 py-2 rounded-full font-light sm:w-auto w-full sm:mt-0 mt-2">
            {"NUTRITION PRODUCTS >"}
          </Button>
        </NavLink>
      </div>
    </section>
  );
}
