import { useState, useContext, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Disclosure, Tab } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import Button from "../../components/Button";
import Personal from "./Personal";
import { getCart, saveCart } from "../../utils/localstorage";
import SiteSelection from "./SiteSelection";
import ArrowRightIcon from "@heroicons/react/24/outline/ArrowRightIcon";
import ArrowLeftIcon from "@heroicons/react/24/outline/ArrowLeftIcon";
import { CartContext } from "../../contexts/CartContext";
import { useNavigate } from "react-router-dom";
import { memberProduct as bundleMemberProduct } from "../bundle";
// import Professional from "./Professional";
// import ShareAndEarn from "./ShareAndEarn";

const categories = ["MEMBER BENEFITS"];

export default function Member() {
  const cartContext = useContext(CartContext);
  const navigate = useNavigate();
  const notify = () => toast("Product is added!");
  const memberProduct = {
    Price: 49.99,
    Sku: "MemKit",
    ProductName: "Member Kit",
    Image: "memkit.png",
  };
  const fiveBooks = {
    Sku: "B3Book5",
    ProductName: "5 Books",
    Price: 29.99,
    Image: "books.png",
  };

  const thirtyBooks = {
    Sku: "B3Book30",
    ProductName: "30 Books",
    Price: 164.99,
    Image: "books.png",
  };
  const ninetyBooks = {
    Sku: "B3Book90",
    ProductName: "90 Books",
    Price: 449.99,
    Image: "books.png",
  };
  const cart = getCart();
  const [page, setPage] = useState("detail");
  const [showBook, setShowBook] = useState(
    cart.find((p) => p.product.Sku === memberProduct.Sku)
  );
  const [prefix, setPrefix] = useState("");
  const [available, setAvailable] = useState("");
  const toastShownRef = useRef(false);

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem("cart"));

    const isMemKitTDPresent = cart?.some(
      (item) => item.product?.Sku === bundleMemberProduct.Sku
    );

    if (isMemKitTDPresent && !toastShownRef.current) {
      toastShownRef.current = true; // Set the flag
      toast("Your Membership is already in your Cart");
      navigate("/check-out");
    }
  }, []);

  const addMemKit = () => {
    setPage("website");
    localStorage.setItem("currentRoute", "website");
    const existing = cart.find((p) => p.product.Sku === memberProduct.Sku);
    setShowBook(true);
    if (existing) {
      return;
    } else {
      cart.push({ product: memberProduct, qty: 1 });
      notify();
    }
    cartContext.save(cart);
  };

  const handleAddToCart = (product) => {
    const existing = cart.find((p) => p.product.Sku === product.Sku);
    if (existing) {
      return;
    } else {
      cart.push({ product: product, qty: 1 });
      notify();
    }
    saveCart(cart);
  };

  const checkAvailability = () => {
    fetch(
      `${process.env.REACT_APP_ORDER_API_URI}/api/rep/replicatedwebsite/${prefix}`
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.replicatedNameOnWebSite) {
          setAvailable("Taken");
        } else {
          localStorage.setItem("memberWebsitePrefix", prefix);
          setAvailable("Available");
        }
      });
  };

  const onContinue = () => {
    const cart = getCart();
    if (prefix) {
      if (available === "Available") {
        setPage("detail");
        localStorage.setItem("currentRoute", "detail");
        const existing = cart.find((p) => p.product.Sku === memberProduct.Sku);
        if (existing) {
          navigate("/check-out");
        } else {
          cart.push({ product: memberProduct, qty: 1 });
          notify();
        }
        saveCart(cart);
        navigate("/check-out");
      } else {
        toast.error("Please check Website availability");
      }
    } else {
      toast.error("Please select Website URL");
    }
  };

  return (
    <section className="container mx-auto flex-1 p-5 flex flex-col lg:max-w-full relative">
      {/* <div>
        <img
          alt="money-guarantee"
          src="./assets/images/guarantee.jpg"
          className="absolute md:w-[100px] w-[60px] top-6 right-5 md:top-2 md:right-2"
        />
      </div> */}
      {page === "detail" && (
        <>
          <h1 className="md:text-5xl pt-2 text-3xl sm:text-4xl mr-[60px] md:mr-0">
            <span>B</span>
            <span className="text-[#00adef] mr-2">3</span>
            <br className="sm:hidden block" />
            <span>MEMBERSHIP BENEFITS</span>
          </h1>
          <div className="flex flex-col gap-16">
            <div className="flex flex-col">
              <div className="flex pt-0 items-center justify-center">
                <img
                  alt="member"
                  src="./assets/images/Member-image.png"
                  className="max-w-[250px] block lg:hidden"
                />
              </div>
              <h1 className="pt-2 text-xl mt-3">
                <span>MEMBER BENEFITS FOR 1 YEAR INCLUDE</span>
              </h1>
              <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1 gap-2">
                <div className="flex flex-col mb-2">
                  <div className="hidden md:block w-full px-2">
                    <Tab.Group>
                      <Tab.List className="flex pt-2">
                        {categories.map((category) => (
                          <Tab
                            key={category}
                            className={({ selected }) =>
                              clsx(
                                "py-1 xl:text-xl font-light leading-5 mr-4 text-lg",
                                "focus:outline-none border-b-[2px] hover:border-gray-800 ",
                                selected
                                  ? "border-gray-800 mr-4"
                                  : "border-transparent"
                              )
                            }
                          >
                            {category}
                          </Tab>
                        ))}
                      </Tab.List>
                      <Tab.Panels className="mt-1">
                        <Tab.Panel key={0}>
                          <Personal />
                        </Tab.Panel>
                        {/* <Tab.Panel key={1}>
                        <Professional />
                      </Tab.Panel>
                      <Tab.Panel key={2}>
                        <ShareAndEarn />
                      </Tab.Panel> */}
                      </Tab.Panels>
                    </Tab.Group>
                  </div>
                  <div className="md:hidden mx-auto w-full rounded-2xl shadow bg-white p-2">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                            <span className="text-lg">Member Benefits</span>
                            <ChevronUpIcon
                              className={`${
                                open ? "rotate-180 transform" : ""
                              } h-5 w-5 text-blue-500`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                            <Personal />
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                    {/* <Disclosure as="div" className="mt-2">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                          <span className='text-lg'>Professional</span>
                          <ChevronUpIcon
                            className={`${open ? 'rotate-180 transform' : ''
                              } h-5 w-5 text-blue-500`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                          <Professional />
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>

                  <Disclosure as="div" className="mt-2">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                          <span className='text-lg'>Share and Earn</span>
                          <ChevronUpIcon
                            className={`${open ? 'rotate-180 transform' : ''
                              } h-5 w-5 text-blue-500`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                          <ShareAndEarn />
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure> */}
                  </div>
                </div>
                <div className="flex pt-0 items-center justify-center">
                  <img
                    alt="member"
                    src="./assets/images/Member-image.png"
                    className="max-w-[320px] hidden lg:block"
                  />
                </div>
              </div>
              <div className="text-base md:text-lg ml-3 font-light my-2">
                ${memberProduct.Price} *BILLED ANNUALLY*
              </div>
              <Button
                onClick={addMemKit}
                className="text-base md:text-lg px-5 py-2 rounded-full font-light mt-2 lg:max-w-[200px]"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
              >
                ADD TO CART
              </Button>
            </div>
          </div>
          {/* {showBook && <>
          <h1 className='md:text-6xl text-5xl pt-10'>
            <span className='text-[#00adef] mr-2'>PURCHASE ADDITIONAL BOOKS</span>
          </h1>
          <div className="flex flex-col gap-16">
            <div className="flex flex-col">
              <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1 gap-2">
                <div className="flex flex-col lg:mt-7 mt-5 gap-6 justify-center">
                  <NavLink key='for more info' target="_blank" to="https://b3sciences.com/what-the-b3-books/">
                    <span className="uppercase">TO LEARN MORE ABOUT THE 'WHAT THE <span className="text-black">B</span>3' BOOKLETS GO HERE (link)</span>
                  </NavLink>
                  <div className='flex items-center font-medium mr-4 sm:flex-row flex-col'>
                    <span className='text-xl sm:mr-0 mr-4'>5 BOOKS: ${fiveBooks.Price}</span>
                    <Button
                      onClick={() => handleAddToCart(fiveBooks)}
                      className="text-base md:text-lg px-5 py-2 rounded-full font-light"
                      data-mdb-ripple="true" data-mdb-ripple-color="light">
                      ADD TO CART
                    </Button>
                  </div>
                  <div className='flex items-center font-medium mr-4 sm:flex-row flex-col'>
                    <span className='text-xl sm:mr-0 mr-4'>30 BOOKS: ${thirtyBooks.Price}</span>
                    <Button
                      onClick={() => handleAddToCart(thirtyBooks)}
                      className="text-base md:text-lg px-5 py-2 rounded-full font-light"
                      data-mdb-ripple="true" data-mdb-ripple-color="light">
                      ADD TO CART
                    </Button>
                  </div>
                  <div className='flex items-center font-medium mr-4 sm:flex-row flex-col'>
                    <span className='text-xl sm:mr-0 mr-4'>90 BOOKS: ${ninetyBooks.Price}</span>
                    <Button
                      onClick={() => handleAddToCart(ninetyBooks)}
                      className="text-base md:text-lg px-5 py-2 rounded-full font-light"
                      data-mdb-ripple="true" data-mdb-ripple-color="light">
                      ADD TO CART
                    </Button>
                  </div>
                </div>
                <div className="flex lg:pt-5 pt-0 justify-center">
                  <NavLink key='for more info' to="https://vimeo.com/808075117">
                    <img alt="books" src="./assets/images/books.png" className="h-[300px]" />
                  </NavLink>
                </div>
              </div>

            </div>
          </div>
        </>} */}
          <div className="w-full flex justify-between mt-10 mb-7 flex-row items-center">
            <NavLink key="b3 bands" to="/nutrition-products">
              <Button className="uppercase text-xs md:text-base px-4 py-1 rounded-full font-light w-auto">
                <div className="inline-block pr-1 relative top-[3px]">
                  <ArrowLeftIcon
                    fill="white"
                    className="h-[0.9rem] md:h-[1.1rem] w-[1.1rem]"
                    aria-hidden="true"
                    color="white"
                  />
                </div>
                {"Back"}
              </Button>
            </NavLink>
            <NavLink key="nutrition products" to="/check-out">
              <Button className="uppercase text-xs md:text-base px-4 py-1 rounded-full font-light w-auto">
                Next
                <div className="inline-block pl-1 relative top-[3px]">
                  <ArrowRightIcon
                    fill="white"
                    className="h-[0.9rem] md:h-[1.1rem] w-[1.1rem]"
                    aria-hidden="true"
                    color="white"
                  />
                </div>
              </Button>
            </NavLink>
          </div>
        </>
      )}
      {page === "website" && (
        <>
          <SiteSelection
            checkAvailability={checkAvailability}
            onContinue={onContinue}
            setPrefix={setPrefix}
            available={available}
            prefix={prefix}
          />
        </>
      )}
    </section>
  );
}
