import ListItem from "../../components/ListItem"

export default function Personal() {
  return (
    <div>
      <ul>
        <ListItem>Extend your BFR Coaching to 1 year (From 30 days)</ListItem>
        <ListItem><span>Personalized <span className="text-black">B</span>3 Mobile App WITH LIBRARY OF BFR WORKOUTS</span></ListItem>
        <ListItem>Wholesale discount on future purchases</ListItem>
        <ListItem><span>YOUR OWN <span className="text-black">B</span>3 WEBSITE FOR REFERRING FRIENDS</span></ListItem>
        <ListItem>EARN UP TO 20% COMMISSIONS PLUS ADDITIONAL 8 LEVELS</ListItem>
        <ListItem>FREE TRAINING ON HOW TO BUILD A BFR BUSINESS</ListItem>
        <ListItem>TRAINING FOR DOCTORS, PTS, ATCS, GYMS & TRAINERS</ListItem>
        <ListItem><span>5 'WHAT THE <span className="text-black">B</span>3 BOOKS' TO SHARE</span></ListItem>
      </ul>
    </div>
  )
}
