import { NavLink, useNavigate } from "react-router-dom";
import AddToCartForm from "../../components/AddToCartForm";
import Button from "../../components/Button";
import Carousel from "../../components/Carousel";
import Bundle from "./Bundle";
import { isProb3 } from "../../utils/siteOrigin";
import BundleAddToCart from "../nutrition-products/BundleAddToCart";
import DoubleBundle from "./DoubleBundle";
import { useState, Fragment } from "react";
import { toast } from "react-toastify";
import { getCart, saveCart } from "../../utils/localstorage";
import PRODUCTS from "../../../public/assets/products.json";
import SiteSelection from "../member/SiteSelection";
import BundleAddToCartForm from "./BundleAddToCartForm";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { Dialog, Transition } from "@headlessui/react";

const SingleSwiperImages = ["bundle-1.png"];

const DoubleSwiperImages = ["bundle-2.png"];

export const memberProduct = {
  Price: 0,
  Sku: "MemKitTD",
  ProductName: "B3 Sciences - Yearly Membership",
  Image: "memkit.png",
};

export default function Page() {
  let navigate = useNavigate();
  const notify = () => toast("Product is added!");
  const [caffeineFree, setCaffeineFree] = useState(false);
  const [caffeineFreeForDouble, setCaffeineFreeForDouble] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [page, setPage] = useState(
    localStorage.getItem("currentRoute") || "detail"
  );
  const [prefix, setPrefix] = useState("");
  const [available, setAvailable] = useState("");

  const onChangeCaffeineFree = () => {
    setCaffeineFree(!caffeineFree);
  };

  const onChangeCaffeineFreeDouble = () => {
    setCaffeineFreeForDouble(!caffeineFreeForDouble);
  };

  const handleAddToCart = () => {
    setPage("website");
    localStorage.setItem("currentRoute", "website");
  };

  const checkAvailability = () => {
    fetch(
      `${process.env.REACT_APP_ORDER_API_URI}/api/rep/replicatedwebsite/${prefix}`
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.replicatedNameOnWebSite) {
          setAvailable("Taken");
        } else {
          localStorage.setItem("memberWebsitePrefix", prefix);
          setAvailable("Available");
          // setIsPopupOpen(true);
          // toast("Your website looks like.");
        }
      });
  };

  const onContinue = () => {
    const cart = getCart();
    if (prefix) {
      if (available === "Available") {
        setPage("detail");
        localStorage.setItem("currentRoute", "detail");
        const existing = cart.find((p) => p.product.Sku === memberProduct.Sku);
        if (existing) {
          navigate("/check-out");
        } else {
          cart.push({ product: memberProduct, qty: 1 });
          notify();
        }
        saveCart(cart);
        navigate("/check-out");
      } else {
        toast.error("Please check Website availability");
      }
    } else {
      toast.error("Please select Website URL");
    }
  };

  const onNextPage = () => {
    const cart = getCart();
    if (
      cart?.filter(
        (c) => c.product.Sku === "RCBurn1" || c.product.Sku === "RCBurn2"
      ).length > 0
    ) {
      navigate("/check-out");
    } else {
      navigate("/bands");
    }
  };
  return (
    <section className="container mx-auto flex-1 p-5 flex flex-col lg:max-w-full relative">
      {page === "detail" && (
        <>
          <h1 className="md:text-6xl pt-2 text-4xl sm:text-5xl">
            <span>B</span>
            <span className="text-[#00adef] mr-2">3</span>
            <span>BURN BUNDLE</span>
          </h1>
          <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1">
            <div className="flex flex-col">
              <h1 className="md:text-2xl pt-2 text-lg sm:text-xl mt-3 font-bold">
                <span>SINGLE BURN PACK – Save $50</span>
              </h1>
              <Bundle />
              <div className="flex flex-row items-start mt-3">
                <label class="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    class="sr-only peer"
                    checked={caffeineFree}
                    onChange={onChangeCaffeineFree}
                  />
                  <div class="w-11 h-6 mr-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
                <label
                  class="text-sm leading-6 text-gray-600"
                  id="switch-1-label"
                >
                  Yes, I would like my{" "}
                  <strong>
                    B<span className="text-[#00adef]">3</span> START
                  </strong>{" "}
                  product Caffeine Free.
                </label>
              </div>
              <div className="">
                <BundleAddToCartForm
                  isSingle
                  sku="RCBurn1"
                  handleAddToCart={() => handleAddToCart()}
                  caffeineFree={caffeineFree}
                />
              </div>
            </div>
            <div className="flex items-center">
              <Carousel
                swiperImages={SingleSwiperImages}
                className="!h-auto lg:h-auto"
              />
            </div>
          </div>

          <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1">
            <div className="flex flex-col mt-2">
              <h1 className="md:text-2xl pt-2 text-lg sm:text-xl mt-3 font-bold">
                <span>DOUBLE BURN PACK – Save $200</span>
              </h1>
              <DoubleBundle />
              <div className="flex flex-row items-start mt-3">
                <label class="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    class="sr-only peer"
                    checked={caffeineFreeForDouble}
                    onChange={onChangeCaffeineFreeDouble}
                  />
                  <div class="shrink-0 w-11 h-6 mr-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
                <label
                  class="text-sm leading-6 text-gray-600"
                  id="switch-1-label"
                >
                  Yes, I would like my{" "}
                  <strong>
                    B<span className="text-[#00adef]">3</span> START
                  </strong>{" "}
                  product Caffeine Free.
                </label>
              </div>
              <div className="">
                <BundleAddToCartForm
                  sku="RCBurn2"
                  handleAddToCart={() => handleAddToCart()}
                  caffeineFree={caffeineFreeForDouble}
                />
              </div>
            </div>
            <div className="flex items-center">
              <Carousel
                swiperImages={DoubleSwiperImages}
                className="!h-auto lg:h-auto"
              />
            </div>
          </div>

          {/* <div className="flex text-center md:justify-center relative items-center flex-col sm:flex-row justify-between">
            <div className="w-full flex items-center justify-center md:justify-start relative mr-2 pt-4">
              <div className="flex flex-col ">
                <img
                  alt="paypal credit"
                  src="./assets/images/paypal-credit.png"
                  className="w-[100px] md:w-[120px]"
                />
                <span className="text-[10px] font-semibold">
                  $0 DOWN 0% INTEREST FOR 6 MONTHS
                </span>
              </div>
              <img
                alt="money-guarantee"
                src="./assets/images/guarantee.jpg"
                className="w-[70px] md:w-[90px] top-6 right-5 md:top-2 ml-2 md:right-2"
              />
            </div>
          </div> */}

          <div className="flex text-center lg:mt-8 mt-5 md:justify-center relative items-center justify-center pb-5">
            <Button
              className="uppercase text-xs md:text-base px-4 py-1 rounded-full font-light w-auto"
              onClick={onNextPage}
            >
              Next
              <div className="inline-block pl-1 relative top-[3px]">
                <ArrowRightIcon
                  fill="white"
                  className="h-[0.9rem] md:h-[1.1rem] w-[1.1rem]"
                  aria-hidden="true"
                  color="white"
                />
              </div>
            </Button>
          </div>
        </>
      )}
      {page === "website" && (
        <>
          <SiteSelection
            checkAvailability={checkAvailability}
            onContinue={onContinue}
            setPrefix={setPrefix}
            available={available}
            prefix={prefix}
          />
          {/* Website modal */}
          {/* <div className="flex justify-center h-full">
            <Transition appear show={isPopupOpen} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-50"
                onClose={() => setIsPopupOpen(false)}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                        <img
                          alt="website"
                          src={`./assets/images/website.png`}
                          className="w-full h-full mt-2"
                        />
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
          </div> */}
        </>
      )}
    </section>
  );
}
