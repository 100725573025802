import { getCart } from "../../utils/localstorage";

export default function Items({subTotal, taxes, shippingCost, total}) {
  const cartItems = getCart();
  return (
    <div className="flex overflow-x-auto">
      <div className='flex flex-col gap-1 md:gap-3 md:text-lg text-sm min-w-[250px]'>
        <h1 className="text-lg md:text-2xl font-light mt-1 md:mt-3">ITEMS: </h1>
        {cartItems.map(p => (
          <div className='grid grid-cols-12 md:text-lg text-sm gap-4 rounded-lg p-3 md:p-5 relative group items-center' key={p.product.Sku}>
            <img alt={p.product.ProductName} src={`./assets/images/products/${p.product.Image}`} className="col-span-2 h-[45px] md:h-[60px]" />
            <p className="sm:col-span-5 hidden sm:block">{p.product.ProductName}</p>
            <p className="sm:col-span-2 col-span-4">${p.product.Price}</p>
            <p className="sm:col-span-1 col-span-2">{p.qty}item(s)</p>
            <p className="sm:col-span-2 col-span-4 text-right">${(p.product.Price * p.qty).toFixed(2)}</p>
          </div>
        ))}
        <div className='grid grid-cols-12 md:text-lg text-sm gap-4 border-t p-3 md:p-5 relative group'>
          <p className="sm:col-span-1 col-span-2 sm:col-start-10 col-start-8">Subtotal:</p>
          <p className="col-span-2 col-start-11 text-right">${subTotal.toFixed(2)}</p>
        </div>
        <div className='grid grid-cols-12 md:text-lg text-sm gap-4 border-t p-3 md:p-5 relative group'>
          <p className="sm:col-span-1 col-span-2 sm:col-start-10 col-start-8">Shipping:</p>
          <p className="col-span-2 col-start-11 text-right">${Number(shippingCost)?.toFixed(2)}</p>
        </div>
        <div className='grid grid-cols-12 md:text-lg text-sm gap-4 border-t p-3 md:p-5 relative group'>
          <p className="sm:col-span-1 col-span-2 sm:col-start-10 col-start-8">Taxes:</p>
          <p className="col-span-2 col-start-11 text-right">${Number(taxes)?.toFixed(2)}</p>
        </div>
        <div className='grid grid-cols-12 md:text-lg text-sm gap-4 border-t p-3 md:p-5 relative group'>
          <p className="sm:col-span-1 col-span-2 sm:col-start-10 col-start-8 font-bold">Total:</p>
          <p className="col-span-2 col-start-11 text-right font-bold">${Number(total)?.toFixed(2)}</p>
        </div>
      </div>
    </div>
  )
}