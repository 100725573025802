import ListItem from "../../components/ListItem";

export default function SingleBundleDetails() {
  return (
    <div>
      <ul>
        <ListItem>
          <span className="text-black">B</span>3 BANDS COMPLETE SET
        </ListItem>
        <ListItem>
          <a
            href="https://b3sciences.com/start"
            target="_blank"
            className="underline mr-2"
          >
            <span className="text-black">B</span>3 START
          </a>{" "}
          30 DAY SUPPLY
        </ListItem>
        <ListItem>LIVE COACHING CALLS & VIDEO LIBRARY </ListItem>
        <ListItem>
          <p>
            PERSONALIZED <span className="text-black ml-1">B</span>3 WEBSITE TO
            SHARE AND EARN
          </p>
        </ListItem>
        <ListItem>
          <div className="flex items-center relative w-full gap-4">
            <span>30 DAY MONEY BACK GUARANTEE</span>
            {/* <img alt="money guarantee" src="./assets/images/guarantee.jpg" className="hidden sm:block w-[100px] absolute right-0 top-[-35px]"/> */}
          </div>
        </ListItem>
        <ListItem>12 MONTH WARRANTY</ListItem>
      </ul>
    </div>
  );
}
