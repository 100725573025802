import PaypalButton from "./PayPalButton";

export default function PaypalSubscription() {
  return (
    <div
      className="relative flex items-center justify-center min-h-screen bg-cover bg-center"
      style={{
        backgroundImage: "url('./assets/images/18.png')",
      }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-2xl text-center relative z-10">
        <img
          src="./assets/images/b3-sciences-logo.png"
          alt="Landing Page Illustration"
          className="mx-auto mb-6 rounded-lg"
        />
        <h1 className="text-4xl font-bold mb-4 text-gray-900">
          B3 U Monthly Subscription
        </h1>
        <p className="text-gray-600 mb-6">
          Unlock Your Potential with B3 U. Subscribe today!
        </p>
        <PaypalButton />
      </div>
    </div>
  );
}
