import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/Button";

const plans = [
  {
    id: 1,
    description: "I received a 'What the B3' book"
  },
  {
    id: 2,
    description: 'A friend, family member, or colleague referred me'
  },
  {
    id: 3,
    description: 'My Doctor, PT, Therapist, Trainer, or Coach referred me'
  },
  {
    id: 4,
    description: 'I found B3 on my own search'
  },
  {
    id: 5,
    description: 'Other'
  },
  {
    id: 6,
    description: 'Sponsor Name'
  },
]

export default function OrderSuccess() {

  let navigate = useNavigate();

  const notify = () => toast.success("Thank you for Survey Answer!");
  const [selected, setSelected] = useState()
  const [other, setOther] = useState('')
  const orderId = localStorage.getItem('orderId')
  const memberId = localStorage.getItem('memberId')
  const reason = other && (selected == 5  || selected == 6) ? other : 'na'

  const submit = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/Order/surveyresults/${orderId}/${selected}/${reason}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(res => res.json())
      .then(res => {
        if (res) {
          notify();
        }
      },
        err => {
          toast.error('Thank you for Survey Answer!')
        })
      .finally(() => {

        window.location.href = 'https://b3sciences.com';
      })
  }

  return (
    <section className="container mx-auto flex-1 p-5 flex flex-col lg:max-w-full relative text-gray-700 items-center">
      <CheckCircleIcon className="h-20 w-20 text-green-900 mt-6" aria-hidden="true" />
      <p className="text-xl font-bold mt-6">Your order has been processed!</p>
      <div className="flex flex-col items-center mt-6 gap-4">
        <p>Order ID: {orderId}</p>
        <p>B3 Account ID: {memberId}</p>
        <p>You will receive 2 emails with your Order Invoice and Shipping Information</p>
      </div>
      <div className="mx-auto w-full max-w-md mt-8">
        <h2 className="text-2xl font-medium mb-3 text-[#00adef] text-center">How did you hear about us?</h2>
        <fieldset>
          <div className="mt-2 space-y-4">
            {plans.map((plan) => (
              <div className="flex flex-col" key={plan.id}>
                <div className="flex items-center gap-x-3 text-lg">
                  <input
                    id={plan.id}
                    name="survey-answer"
                    type="radio"
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    value={plan.id}
                    onChange={(e) => { setSelected(e.target.value) }}
                  />
                  <label htmlFor="push-everything" className="block text-base font-medium leading-6 text-gray-900">
                    {plan.description}
                  </label>
                </div>
                {((selected == 5 && plan.id === 5) || (selected == 6 && plan.id === 6)) && <input value={other} onChange={(e) => { setOther(e.target.value) }} className="block flex-1 rounded border border-gray-400 px-2 py-1.5 w-full text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6 mt-2 ml-7" />}
              </div>
            ))}
          </div>
        </fieldset>
      </div>
      <div className='w-full flex justify-center mt-10 sm:flex-row flex-col'>
        <Button className="text-base md:text-lg px-5 py-2 rounded-full font-light sm:w-auto w-full" onClick={submit}>
          {'Submit Answer'}
        </Button>
      </div>
    </section>
  )
}