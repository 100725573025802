export function getCart() {
  const cartStr = localStorage.getItem('cart') || '[]'
  try {
    const cart = JSON.parse(cartStr)
    return cart
  } catch (e) {
    console.log('Failed to load cart: ', e)
    return []
  }
}

export function saveCart(cart) {
  localStorage.setItem('cart', JSON.stringify(cart))
}
