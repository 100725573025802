export default function BillingInfo({billingInfo}) {
  return (
    <div className='flex flex-col gap-3 md:text-lg text-sm text-gray-700 py-5'>
      <div className="grid grid-cols-4 gap-2">
        <div className="lg:col-span-1 col-span-2 flex flex-col">
          <span>Name</span>
          <span className="text-gray-900">{billingInfo?.firstName} {billingInfo?.lastName}</span>
        </div>
        <div className="lg:col-span-1 col-span-2 flex flex-col">
          <span>Phone</span>
          <span className="text-gray-900">{billingInfo?.phone}</span>
        </div>
        <div className="lg:col-span-1 col-span-2 flex flex-col">
          <span>Country</span>
          <span className="text-gray-900">USA</span>
        </div>
        <div className="lg:col-span-1 col-span-2 flex flex-col">
          <span>Email</span>
          <span className="text-gray-900">{billingInfo?.email}</span>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-2">
        <div className="lg:col-span-1 col-span-2 flex flex-col">
          <span>City</span>
          <span className="text-gray-900">{billingInfo?.city}</span>
        </div>
        <div className="lg:col-span-1 col-span-2 flex flex-col">
          <span>State</span>
          <span className="text-gray-900">{billingInfo?.state}</span>
        </div>
        <div className="lg:col-span-1 col-span-2 flex flex-col">
          <span>Zipcode</span>
          <span className="text-gray-900">{billingInfo?.postalCode}</span>
        </div>
        <div className="lg:col-span-1 col-span-2 flex flex-col">
          <span>Address</span>
          <span className="text-gray-900">{billingInfo?.address}</span>
        </div>
      </div>
    </div>
  )
}