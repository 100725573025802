import React, { useEffect } from "react";

export default function PaypalSubscription() {
  useEffect(() => {
    // Dynamically load PayPal SDK script
    const loadPayPalScript = () => {
      if (document.getElementById("paypal-sdk")) return; // Prevent duplicate script loading

      const script = document.createElement("script");
      script.id = "paypal-sdk";
      script.src =
        "https://www.paypal.com/sdk/js?client-id=AQpmanAwpoajq1GvByBfi3aXQqdhKybizx9BqfadBget2qFz_KIK_t56Xa73J7s5ucgcTkECI28By1MH&vault=true&intent=subscription";
      script.setAttribute("data-sdk-integration-source", "button-factory");

      script.onload = () => {
        if (window.paypal) {
          window.paypal
            .Buttons({
              style: {
                shape: "rect",
                color: "blue",
                layout: "vertical",
                label: "subscribe",
              },
              createSubscription: function (data, actions) {
                return actions.subscription.create({
                  /* Creates the subscription */
                  plan_id: "P-5A453018CU0552926M43V43Y",
                });
              },
              onApprove: function (data) {
                alert(`Subscription successful! ID: ${data.subscriptionID}`);
              },
            })
            .render("#paypal-button-container-P-5A453018CU0552926M43V43Y");
        }
      };

      script.onerror = () => {
        console.error("Failed to load the PayPal SDK script.");
      };

      document.body.appendChild(script);
    };

    loadPayPalScript();

    return () => {
      // Cleanup PayPal buttons on unmount
      const paypalButtonsContainer = document.querySelector(
        "#paypal-button-container-P-5A453018CU0552926M43V43Y"
      );
      if (paypalButtonsContainer) {
        paypalButtonsContainer.innerHTML = "";
      }
    };
  }, []);

  return <div id="paypal-button-container-P-5A453018CU0552926M43V43Y"></div>;
}
