import { RouterProvider } from "react-router-dom";
import { router } from "./Router";
import 'react-toastify/dist/ReactToastify.css';
import { CartContextProvider } from "./contexts/CartContext";

export default function App() {
  return (
    <CartContextProvider>
      <RouterProvider router={router} />
    </CartContextProvider>
  )
}
