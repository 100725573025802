import { NavLink, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { Field, Form, Formik, useFormikContext } from "formik";

import { shippingFormSchema } from "../../utils/validation";
import { useEffect } from "react";
import { getCart } from "../../utils/localstorage";
import ArrowRightIcon from "@heroicons/react/24/outline/ArrowRightIcon";
import ArrowLeftIcon from "@heroicons/react/24/outline/ArrowLeftIcon";

const USStates = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

const ShippingSame = () => {
  const { values, setValues } = useFormikContext();

  useEffect(() => {
    if (values.sameAsBilling) {
      setValues((prev) => ({
        ...prev,
        ShipToFirstName: prev.firstName,
        ShipToLastName: prev.lastName,
        ShipToAddress: prev.address,
        ShipToApt: prev.apt,
        ShipToCity: prev.city,
        ShipToState: prev.state,
        ShipToPostalCode: prev.postalCode,
        ShipToPhone: prev.phone,
        ShipToEmail: prev.email,
      }));
    }
  }, [values.sameAsBilling, setValues]);

  return null;
};

export default function Shipping() {
  const shippingInfo = JSON.parse(localStorage.getItem("shippingAddress"));
  const billingInfo = JSON.parse(localStorage.getItem("billingAddress"));
  const memberWebsitePassword =
    localStorage.getItem("memberWebsitePassword") || "";
  const cart = getCart();
  const memkitExisting = !!cart.find(
    (p) => p.product.Sku === "MemKit" || p.product.Sku === "MemKitTD"
  );
  let navigate = useNavigate();
  const handleSubmit = (values, { setSubmitting }) => {
    const shippingAddress = {
      ShipToFirstName: values.ShipToFirstName,
      ShipToLastName: values.ShipToLastName,
      ShipToAddress: values.ShipToAddress,
      ShipToApt: values.ShipToApt,
      ShipToCity: values.ShipToCity,
      ShipToState: values.ShipToState,
      ShipToPostalCode: values.ShipToPostalCode,
      ShipToPhone: values.ShipToPhone,
      ShipToEmail: values.ShipToEmail,
    };
    const billingAddress = {
      firstName: values.firstName,
      lastName: values.lastName,
      address: values.address,
      apt: values.apt,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      phone: values.phone,
      email: values.email,
    };
    localStorage.setItem("shippingAddress", JSON.stringify(shippingAddress));
    localStorage.setItem("billingAddress", JSON.stringify(billingAddress));
    localStorage.setItem(
      "memberWebsitePassword",
      memkitExisting ? values.memberWebsitePassword : ""
    );
    navigate("/payment");
  };
  return (
    <Formik
      initialValues={{
        ...shippingInfo,
        ...billingInfo,
        showPassword: memkitExisting,
        memberWebsitePassword: memberWebsitePassword,
      }}
      validationSchema={shippingFormSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, submitCount, handleSubmit }) => {
        return (
          <Form>
            <ShippingSame />
            <section className="container mx-auto flex-1 p-5 flex flex-col lg:max-w-full relative">
              <div className="flex flex-col text-gray-700">
                <h3 className="text-2xl font-semibold py-4 border-b">
                  Billing Information
                </h3>
                <div className="text-md mt-4">* Indicates a Required Field</div>

                <div className="flex flex-col gap-4 mt-6">
                  <div className="flex flex-col lg:flex-row gap-4">
                    <div className="flex flex-col w-full">
                      <span>First Name*</span>
                      <Field
                        name="firstName"
                        className="block rounded border border-gray-400 px-2 py-1.5 w-full text-gray-900  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6"
                        placeholder=""
                      />
                      {errors.firstName &&
                        (touched.firstName || submitCount > 0) && (
                          <span className="text-sm text-red-400">
                            {errors.firstName}
                          </span>
                        )}
                    </div>
                    <div className="flex flex-col w-full">
                      <span>Last Name*</span>
                      <Field
                        name="lastName"
                        className="block rounded border border-gray-400 px-2 py-1.5 w-full h-[36px] text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6"
                        placeholder=""
                      />
                      {errors.lastName &&
                        (touched.lastName || submitCount > 0) && (
                          <span className="text-sm text-red-400">
                            {errors.lastName}
                          </span>
                        )}
                    </div>
                  </div>

                  <div className="flex flex-col lg:flex-row gap-4">
                    <div className="flex flex-col w-full">
                      <span>Address*</span>
                      <Field
                        name="address"
                        className="block rounded border border-gray-400 px-2 py-1.5 w-full text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6"
                        placeholder=""
                      />
                      {errors.address &&
                        (touched.address || submitCount > 0) && (
                          <span className="text-sm text-red-400">
                            {errors.address}
                          </span>
                        )}
                    </div>
                    <div className="flex flex-col w-full">
                      <span>Apt/Suite</span>
                      <Field
                        name="apt"
                        className="block rounded border border-gray-400 px-2 py-1.5 w-full text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6"
                        placeholder=""
                      />
                      <span className="text-sm text-red-400"></span>
                    </div>
                  </div>

                  <div className="flex flex-col lg:flex-row gap-4">
                    <div className="flex flex-col w-full">
                      <span>City*</span>
                      <Field
                        name="city"
                        className="block rounded border border-gray-400 px-2 py-1.5 w-full text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6"
                        placeholder=""
                      />
                      {errors.city && (touched.city || submitCount > 0) && (
                        <span className="text-sm text-red-400">
                          {errors.city}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col w-full">
                      <span>State*</span>
                      <Field
                        as="select"
                        name="state"
                        className="block rounded border border-gray-400 px-2 py-1.5 w-full text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6 h-[36px]"
                        placeholder=""
                      >
                        <option defaultValue>Please select the State</option>
                        {USStates.map((state) => (
                          <option value={state}>{state}</option>
                        ))}
                      </Field>
                      {errors.state && (touched.state || submitCount > 0) && (
                        <span className="text-sm text-red-400">
                          {errors.state}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col w-full">
                      <span>Zip/Postal Code*</span>
                      <Field
                        name="postalCode"
                        className="block rounded border border-gray-400 px-2 py-1.5 w-full text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6"
                        placeholder=""
                      />
                      {errors.postalCode &&
                        (touched.postalCode || submitCount > 0) && (
                          <span className="text-sm text-red-400">
                            {errors.postalCode}
                          </span>
                        )}
                    </div>
                  </div>

                  <div className="flex flex-col lg:flex-row gap-4">
                    <div className="flex flex-col w-full">
                      <span>Phone*</span>
                      <Field
                        name="phone"
                        className="block rounded border border-gray-400 px-2 py-1.5 w-full text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6"
                        placeholder=""
                      />
                      {errors.phone && (touched.phone || submitCount > 0) && (
                        <span className="text-sm text-red-400">
                          {errors.phone}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col w-full">
                      <span>Email*</span>
                      <Field
                        type="email"
                        name="email"
                        className="block rounded border border-gray-400 px-2 py-1.5 w-full text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6"
                        placeholder=""
                      />
                      {errors.email && (touched.email || submitCount > 0) && (
                        <span className="text-sm text-red-400">
                          {errors.email}
                        </span>
                      )}
                    </div>
                  </div>
                  {memkitExisting && (
                    <div className="flex flex-col lg:flex-row gap-4">
                      <div className="flex flex-col w-full">
                        <span>Member Password*</span>
                        <Field
                          name="memberWebsitePassword"
                          className="block rounded border border-gray-400 px-2 py-1.5 w-full text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6"
                          placeholder=""
                        />
                        {!errors.memberWebsitePassword &&
                          !touched.memberWebsitePassword && (
                            <span className="text-sm">
                              At least 1 capital letter, 1 small letter, 1
                              number and 1 special character, Min 6 characters
                            </span>
                          )}
                        {errors.memberWebsitePassword &&
                          (touched.memberWebsitePassword ||
                            submitCount > 0) && (
                            <span className="text-sm text-red-400">
                              {errors.memberWebsitePassword}
                            </span>
                          )}
                      </div>
                      <div className="flex flex-col w-full"></div>
                    </div>
                  )}
                </div>
              </div>

              {/* Shipping Information */}
              <div className="flex flex-col text-gray-700 mt-6">
                <h3 className="text-2xl font-semibold py-4 border-b">
                  Shipping Information
                </h3>
                <div className="text-md mt-4">* Indicates a Required Field</div>

                <div className="flex flex-col gap-4 mt-6">
                  <div className="flex h-6 items-center">
                    <Field
                      id="sameAsBilling"
                      name="sameAsBilling"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <span className="text-sm ml-3">
                      Shipping Info Same as Billing Info
                    </span>
                  </div>

                  <div className="flex flex-col lg:flex-row gap-4">
                    <div className="flex flex-col w-full">
                      <span>First Name*</span>
                      <Field
                        name="ShipToFirstName"
                        disabled={values.sameAsBilling}
                        className="block rounded border border-gray-400 px-2 py-1.5 w-full text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6"
                        placeholder=""
                      />
                      {errors.ShipToFirstName &&
                        (touched.ShipToFirstName || submitCount > 0) && (
                          <span className="text-sm text-red-400">
                            {errors.ShipToFirstName}
                          </span>
                        )}
                    </div>
                    <div className="flex flex-col w-full">
                      <span>Last Name*</span>
                      <Field
                        name="ShipToLastName"
                        disabled={values.sameAsBilling}
                        className="block rounded border border-gray-400 px-2 py-1.5 w-full h-[36px] text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6"
                        placeholder=""
                      />
                      {errors.ShipToLastName &&
                        (touched.ShipToLastName || submitCount > 0) && (
                          <span className="text-sm text-red-400">
                            {errors.ShipToLastName}
                          </span>
                        )}
                    </div>
                  </div>

                  <div className="flex flex-col lg:flex-row gap-4">
                    <div className="flex flex-col w-full">
                      <span>Address*</span>
                      <Field
                        name="ShipToAddress"
                        disabled={values.sameAsBilling}
                        className="block rounded border border-gray-400 px-2 py-1.5 w-full text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6"
                        placeholder=""
                      />
                      {errors.ShipToAddress &&
                        (touched.ShipToAddress || submitCount > 0) && (
                          <span className="text-sm text-red-400">
                            {errors.ShipToAddress}
                          </span>
                        )}
                    </div>
                    <div className="flex flex-col w-full">
                      <span>Apt/Suite</span>
                      <Field
                        name="ShipToApt"
                        disabled={values.sameAsBilling}
                        className="block rounded border border-gray-400 px-2 py-1.5 w-full text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6"
                        placeholder=""
                      />
                      <span className="text-sm text-red-400"></span>
                    </div>
                  </div>

                  <div className="flex flex-col lg:flex-row gap-4">
                    <div className="flex flex-col w-full">
                      <span>City*</span>
                      <Field
                        name="ShipToCity"
                        disabled={values.sameAsBilling}
                        className="block rounded border border-gray-400 px-2 py-1.5 w-full text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6"
                        placeholder=""
                      />
                      {errors.ShipToCity &&
                        (touched.ShipToCity || submitCount > 0) && (
                          <span className="text-sm text-red-400">
                            {errors.ShipToCity}
                          </span>
                        )}
                    </div>
                    <div className="flex flex-col w-full">
                      <span>State*</span>
                      <Field
                        as="select"
                        name="ShipToState"
                        disabled={values.sameAsBilling}
                        className="block rounded border border-gray-400 px-2 py-1.5 w-full text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6 h-[36px]"
                        placeholder=""
                      >
                        <option defaultValue>Please select the State</option>
                        {USStates.map((state) => (
                          <option value={state}>{state}</option>
                        ))}
                      </Field>
                      {errors.ShipToState &&
                        (touched.ShipToState || submitCount > 0) && (
                          <span className="text-sm text-red-400">
                            {errors.ShipToState}
                          </span>
                        )}
                    </div>
                    <div className="flex flex-col w-full">
                      <span>Zip/Postal Code*</span>
                      <Field
                        name="ShipToPostalCode"
                        disabled={values.sameAsBilling}
                        className="block rounded border border-gray-400 px-2 py-1.5 w-full text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6"
                        placeholder=""
                      />
                      {errors.ShipToPostalCode &&
                        (touched.ShipToPostalCode || submitCount > 0) && (
                          <span className="text-sm text-red-400">
                            {errors.ShipToPostalCode}
                          </span>
                        )}
                    </div>
                  </div>

                  <div className="flex flex-col lg:flex-row gap-4">
                    <div className="flex flex-col w-full">
                      <span>Phone*</span>
                      <Field
                        name="ShipToPhone"
                        disabled={values.sameAsBilling}
                        className="block rounded border border-gray-400 px-2 py-1.5 w-full text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6"
                        placeholder=""
                      />
                      {errors.ShipToPhone &&
                        (touched.ShipToPhone || submitCount > 0) && (
                          <span className="text-sm text-red-400">
                            {errors.ShipToPhone}
                          </span>
                        )}
                    </div>
                    <div className="flex flex-col w-full">
                      <span>Email*</span>
                      <Field
                        type="email"
                        name="ShipToEmail"
                        disabled={values.sameAsBilling}
                        className="block rounded border border-gray-400 px-2 py-1.5 w-full text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6"
                        placeholder=""
                      />
                      {errors.ShipToEmail &&
                        (touched.ShipToEmail || submitCount > 0) && (
                          <span className="text-sm text-red-400">
                            {errors.ShipToEmail}
                          </span>
                        )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full flex justify-between mt-10 mb-7 flex-row items-center">
                <NavLink key="b3 bands" to="/check-out">
                  <Button className="uppercase text-xs md:text-base px-4 py-1 rounded-full font-light w-auto">
                    <div className="inline-block pr-1 relative top-[3px]">
                      <ArrowLeftIcon
                        fill="white"
                        className="h-[0.9rem] md:h-[1.1rem] w-[1.1rem]"
                        aria-hidden="true"
                        color="white"
                      />
                    </div>
                    {"Back"}
                  </Button>
                </NavLink>
                <Button className="uppercase text-xs md:text-base px-4 py-1 rounded-full font-light w-auto">
                  Checkout
                  <div className="inline-block pl-1 relative top-[3px]">
                    <ArrowRightIcon
                      fill="white"
                      className="h-[0.9rem] md:h-[1.1rem] w-[1.1rem]"
                      aria-hidden="true"
                      color="white"
                    />
                  </div>
                </Button>
              </div>
            </section>
          </Form>
        );
      }}
    </Formik>
  );
}
