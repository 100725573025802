import React from "react";
import Button from "../../components/Button";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
const cardData = [
  {
    text: "B3 Burn Bundle",
    img: "bundle-pic-new.png",
    link: "/bundle",
    buttonText: "Chose Bundle",
  },
  {
    text: "B3 Bands",
    img: "sarah-mike-bands.png",
    link: "/bands",
    buttonText: "Chose Bands",
  },
  {
    text: "B3 Nutrition Products",
    img: "22.png",
    link: "/nutrition-products",
    buttonText: "Chose Product",
  },
  {
    text: "B3 Member",
    img: "dr-adams-member.jpg",
    link: "/become-a-member",
    buttonText: "Check Benefits",
  },
];

export default function Page() {
  return (
    <div className="grid gap-6 py-8 px-2 md:py-4 md:pt-[10rem] grid-cols-1 lg:grid-cols-4">
      {cardData.map((card, index) => (
        <div key={index} className="space-y-6">
          <Card {...card} index={index} />
        </div>
      ))}
    </div>
  );
}

function Card({ img, text, link, buttonText, index }) {
  return (
    <a
      href={link}
      className="transition duration-300 hover:scale-105 relative h-full isolate flex flex-col justify-end overflow-hidden rounded-b-2xl mx-auto border "
    >
      <img
        src={`./assets/images/${img}`}
        alt="University of Southern California"
        className={`inset-0 h-full w-full object-cover ${
          index === 1 ? "object-[37%]" : " object-[10%]"
        } `}
      />
      <div className="grid z-10 p-4 bg-[#00adef] min-h-[100px]">
        <div className="flex items-center gap-2 justify-between">
          <h3 className="text-xl font-bold text-black">{text}</h3>
          <div className="inline-block p-2 rounded-full bg-[#00adef] border border-white border-[1.5px] px-[15px]">
            <ArrowRightIcon
              fill="white"
              className="h-4 w-4 md:h-5 md:w-5"
              aria-hidden="true"
              color="white"
            />
          </div>
        </div>
      </div>
    </a>
  );
}
