import ListItem from "../../components/ListItem";

export default function WhatsIncluded() {
  return (
    <div>
      <ul>
        <ListItem>(2)ARM BANDS, (2)LEG BANDS, HANDHELD PUMP</ListItem>
        <ListItem>
          <span className="text-black">B</span>3 BACKPACK
        </ListItem>
        <ListItem>FREE 30 DAY COACHING</ListItem>
        <ListItem>FREE EXERCISE TUBING</ListItem>
        <ListItem>
          <div className="flex items-center relative pr-[120px]">
            <span>30 DAY MONEY BACK GUARANTEE</span>
            {/* <img alt="money guarantee" src="./assets/images/guarantee.jpg" className="w-[100px] absolute right-0 top-[-35px]"/> */}
          </div>
        </ListItem>
        <ListItem>12 MONTH WARRANTY</ListItem>
      </ul>
    </div>
  );
}
