import { NavLink } from "react-router-dom"

const Lists = [
  {
    id: 1,
    content: 'Become the BFR Expert in your area',
  },
  {
    id: 2,
    content: 'Taught by Dr. Mike DeBord, BFR Expert ',
  },
  {
    id: 3,
    content: '6 Hour Online Course',
  },
  {
    id: 4,
    content: 'Certificate and BFR Certification # will be issued to students who pass the exam',
  },
  {
    id: 5,
    content: "Approved for CEU’s for various Health Professionals",
  },
  {
    id: 6,
    content: 'For more information click here',
    link: "https://b3sciences.com/bfr-certified-coach/"
  },
]

export default function CertificationCourseDetails() {
  return (
    <div className="z-10">
      <ul>
        {Lists.map((post) => (
          <div
            key={post.id}
            className="relative xl:py-1"
          >
            {post.link &&
              <NavLink key='for more info' to="https://b3sciences.com/start/" target="_blank">
                <div className="xl:text-xl lg:text-lg font-light leading-5 italic text-[#00adef] underline uppercase">- {post.content}</div>
              </NavLink>}
            {!post.link &&
              <p className="xl:text-xl lg:text-lg font-light leading-5 text-base text-[#00adef] uppercase">
                - {post.content}
              </p>}
          </div>
        ))}
      </ul>
    </div>
  )
}
